$.ajaxSetup({
    headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
});


window.check_unlosta_vehicle_box = function (id, unlosta_line) {
    if (!$("#unlosta_prop_id_" + id + "").is(":checked")) {
        $("tr#unlosta_line_" + unlosta_line + " :checkbox").prop("checked", false);
    }
}

window.check_unlosta_property_box = function (id, depends_id) {
    if ($("#unlosta_prop_id_" + id + "").is(":checked")) {
        $("td #unlosta_prop_id_" + depends_id + ":checkbox").prop("checked", true);
    }
}

window.broker_match_check_all = function () {
    if ($("#select_all").is(":checked")) {
        $("td [id^='carrier_id_']:checkbox").prop("checked", true);
    }

    if (!$("#select_all").is(":checked")) {
        $("td [id^='carrier_id_']:checkbox").prop("checked", false);
    }
}
